import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class ExternalApiSyncService {

    constructor(
        private http: HttpClient
    ) { }

    getDistrictSyncUpdate(params: any) {
        return this.http.get(`${environment.apiUrl}/raj-e-panchayat/raj-panchayat-district-list`, { params: params });
    }

    getAuditUnitSyncUpdate(params: any) {
        return this.http.get(`${environment.apiUrl}/raj-e-panchayat/raj-panchayat-audit-unit-list`, { params: params });
    }

    getFocusAreaSyncUpdate(params: any) {
        return this.http.get(`${environment.apiUrl}/raj-e-panchayat/raj-panchayat-focus-area-list`, { params: params });
    }
    getBudgetHeadImportUpdate(params:any){
        return this.http.get(`${environment.apiUrl}/ifms/ifms-budget-head-list`, { params: params });
    }

    saveDistricPanchayat(param: any) {
        return this.http.put(`${environment.apiUrl}/raj-e-panchayat/save-district-list`, param);
    }

    saveAuditUnitPanchayat(param: any) {
        return this.http.put(`${environment.apiUrl}/raj-e-panchayat/save-audit-unit-list`, param);
    }

    saveFocusAreaPanchayat(param: any) {
        return this.http.put(`${environment.apiUrl}/raj-e-panchayat/save-focus-area-list`, param);
    }
    saveBudgetHeadAuditArea(param: any) {
        return this.http.put(`${environment.apiUrl}/ifms/save-budget-head-list`, param);
    }


    postDistrictSync(): any {
        return this.http.post(`${environment.apiUrl}/raj-e-panchayat/sync-district`, null);
    }

    postAuditUnitSync(): any {
        return this.http.post(`${environment.apiUrl}/raj-e-panchayat/sync-audit-unit`, null);
    }

    postFocusAreaSync(): any {
        return this.http.post(`${environment.apiUrl}/raj-e-panchayat/sync-work`, null);
    }

    getRiskSyncDrpdwn(): any {
        return this.http.get(`${environment.apiUrl}/raj-e-panchayat/get-risk-params`);
    }

    saveRiskSyncDrpdwn(params: object): any {
        return this.http.post(`${environment.apiUrl}/raj-e-panchayat/map-risk-param`, params);
    }

}




