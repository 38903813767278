import { Component, OnInit,Inject, OnDestroy ,ViewEncapsulation, HostListener} from '@angular/core';
import { Route, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {MatSort,MatTableDataSource,MatPaginator,MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ILoadedEventArgs, ChartTheme } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { AuditService } from '../../_services/audit.service';
import { CommonService } from '../../_services/common.service';
import { NgxUiLoaderModule, NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { parse } from 'querystring';
import { parseJSON } from 'jquery';
import { element } from '@angular/core/src/render3/instructions';


@Component({
  selector: 'app-check-data',
  templateUrl: './check-data.component.html',
  styleUrls: ['./check-data.component.css']
})
export class CheckDataComponent implements OnInit {


    
    msg;
    p: number = 0;
    successMsg: boolean = false;
    errorMsg: boolean = false;
    data: any;
    inputData: string = '';
    outputData: string[] = [];
    queryData :any = [];


  dataKeys:any = [];
    fetchData(): void {
      this.ngxService.start();
      this.auditService.fetchData(this.inputData).subscribe(
        (data: any) => {

          this.outputData = data;

          for (let key in data[0]){
            this.dataKeys.push(key);
          }

          console.log('dataKeys: ', this.dataKeys);

          this.ngxService.stop();  
          this.successMsg = true;
                    this.msg = 'Query executed successfully';
                    setTimeout(() => {
                        this.successMsg = false;
                    }, this.common.timeOut);
        },
        (error: Error) => {
          this.ngxService.stop();
          this.msg = 'Unable to fetch the output';
          this.errorMsg = true;
          setTimeout(() => {
            this.errorMsg = false;
        }, this.common.timeOut);
        }
      );
    }

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private auditService:AuditService,
    private ngxService:NgxUiLoaderService,
    private common:CommonService) { }

  ngOnInit() {
  }

}
